<template>
  <div class="app-container summary-page">
    <eHeader
      :query="query"
      @query="queryHandle"
      @clear="clear"
      :show-export="false"
    />
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      stripe
      size="small"
      style="width: 100%;"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column prop="transactionId" label="订单号"/>
      <el-table-column prop="MT4" label="MT账号">
        <template slot-scope="scope">
            <span v-if="scope.row.login!=null">{{scope.row.login}}<br></span>
            <span v-if="scope.row.group!=null">{{scope.row.group}}</span>
            <span v-if="scope.row.login==null&&scope.row.group==null">无</span>
        </template>
      </el-table-column>
      <el-table-column  label="客户信息" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.userName!=null">{{scope.row.userName}}<br></span>
          <span v-if="scope.row.custId!=null">{{scope.row.custId}}<br></span>
          <span v-if="scope.row.email!=null">{{scope.row.email}}</span>
          <span v-if="scope.row.custId==null&&scope.row.phone==null&&scope.row.email==null">无</span>
        </template>
      </el-table-column>
      <el-table-column prop="manager" label="客户经理">
        <template slot-scope="scope">
            <span v-if="scope.row.managerName!=null">{{scope.row.managerName}}<br></span>
            <span v-if="scope.row.managerId!=null">{{scope.row.managerId}}</span>
            <span v-if="scope.row.managerName==null&&scope.row.managerId==null">无</span>
        </template>
      </el-table-column>
      <el-table-column prop="payType" label="出金类型">
        <template slot-scope="scope">
          <span>{{ getPayType(scope.row.payTypeId) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="payType" label="支付渠道">
         <template slot-scope="scope">
          <span>{{ scope.row.payDetail }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="currency" label="支付币种"/>
      <el-table-column prop="amount" label="金额 ($)"/>
      <el-table-column prop="status" label="付款状态">
        <template slot-scope="scope">
          <span>{{ parsePayStatus(scope.row.payStatus) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="审核状态">
        <template slot-scope="scope">
          <span>{{ parseWithdrawStatus(scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="提交时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="审核通过时间">
        <template slot-scope="scope">
          <span>{{ getAuditTime(scope.row.statusTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150px" align="center">
        <template slot-scope="scope">
          <check
            class="my-button"
            v-if="checkPermission(['ADMIN','WITHDRAW_ALL','WITHDRAW_VIEW'])"
            :data="scope.row"
            :sup_this="sup_this"
            :isCheck="false"
            :tableStatus="scope.row.status"
          />
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/finance/withdraw/header'
import check from '@/components/finance/withdraw/view'
import { parseWithdrawStatus,parseCurrencyType ,parseOutType,parsePayStatus} from '@/views/pt/ib/utils/index'
export default {
  name:'withdraw',
  components: { eHeader,check },
  mixins: [initData],
  props:{
  },
  data() {
    return {
      loading: false,
      sup_this: this,
      statistics:{
        amountSum: ''
      },
      withdrawTypeOption:[
        { value: 4, label: '出金至银行卡'},
        { value: 5, label: '出金至USDT'},
        { value: 6, label: '出金至OTC365'},
      ]
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    parseWithdrawStatus,
    parseOutType,
    parseCurrencyType,
    parsePayStatus,
    beforeInit() {
      this.url = '/crm/tCustDepositWithdraw'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort , type:2}
      const query = this.query;
      const transactionId = query.transactionId;
      const login = query.login;
      const status = query.status;
      const payType = query.payType;
      const createTime = this.parseTime(query.createTime);
      const updateTime = this.parseTime(query.updateTime);
      const managerInfo = query.managerInfo;
      if (managerInfo!=null && managerInfo!='') { this.params['managerInfo'] = managerInfo };
      if (transactionId!=null && transactionId!='') { this.params['transactionId'] = transactionId };
      if (login!=null && login!='') { this.params['login'] = login };
      if (status!=null && status!='') { this.params['status'] = status };

      if (createTime!=null && createTime!='') { this.params['createTime'] = createTime };
      if (updateTime!=null && updateTime!='') { this.params['updateTime'] = updateTime };
      if (payType!=null && payType!='' && (payType && payType.length)) {
        this.params['payTypes'] = payType.join();
      };
      if(this.query.withdrawType){
        this.params.payTypeId = this.query.withdrawType;
      }
      if(this.query.startAuditTime){
        this.params.startAuditTime = this.parseTime(this.query.startAuditTime);
      }
      if(this.query.endAuditTime){
        this.params.endAuditTime = this.parseTime(this.query.endAuditTime);
      }
      if(this.query.custInfo){
        this.params.custInfo = this.query.custInfo;
      }
      return true
    },
    queryHandle(){
      this.page = 0;
      this.init();
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        switch (index) {
          case 0:
            sums[index] = '合计';
            break;
          case 7:
            sums[index] = `${this.statistics.amountSum}`;
            break;
        }
      });

      return sums;
    },
    getPayType(payTypeId){
      for (let i = 0; i < this.withdrawTypeOption.length; i++) {
        if(this.withdrawTypeOption[i].value == payTypeId){
          return this.withdrawTypeOption[i].label;
          break;
        }
      }
    },
    getAuditTime(auditTime){
      if(auditTime){
        return parseTime(auditTime);
      }else {
        return '-'
      }
    },
    clear(){
      this.query = {};
      this.toQuery();
    }
  }
}
</script>

<style scoped>

</style>
